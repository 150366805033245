@import 'styles/base/color.scss';

// html,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
//take from old
body {
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  font-size: 0.7rem;
  font-family: Poppins, Open Sans, sans-serif;
  font-weight: 400;
  line-height: 1.1;
  background-color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #222;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 0.5rem;
  margin-top: 0.2rem;
  text-rendering: optimizeLegibility;
}
p {
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.6;
  margin: 0;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility;
}
input {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-family: inherit;
  font-size: 0.875rem;
  // height: 2.3125rem;
  margin: 0 0 1rem;
  // padding: 0.5rem;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.15s linear, background 0.15s linear;
  &:focus {
    outline: none;
  }
}
.button,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0;
  cursor: pointer;
  font-family: Helvetica Neue, Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 1.25rem;
  position: relative;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 1rem 2rem 1.0625rem;
  font-size: 1rem;
  background-color: #008cba;
  border: 0 solid #007095;
  color: #fff;
  transition: background-color 0.3s ease-out;
}
label {
  color: #4d4d4d;
  cursor: pointer;
  display: block;
  font-size: 0.875rem;
  font-weight: 400;
  // line-height: 1.5;
  margin-bottom: 0;
}
img {
  display: inline-block;
  vertical-align: middle;
}
div {
  box-sizing: border-box;
}
hr {
  border: solid #ddd;
  border-width: 1px 0 0;
  clear: both;
  height: 0;
  margin: 1.25rem 0 1.1875rem;
}
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  box-sizing: border-box;
}
//
ol,
ul {
  list-style: none;
  line-height: 1.6;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.header {
  //left header nav section
  &__block {
    @media print {
      display: none;
    }
    background: rgb(255, 255, 255);
    box-shadow: 0px 4px 24px 0px #3b3b3b21;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    // height: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;
    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: linear-gradient(
          90deg,
          #3e82ff 9.62%,
          rgba(62, 130, 255, 0.63) 28.47%,
          #d1d1d1 65.06%
        ),
        linear-gradient(0deg, #fafafa, #fafafa);
      z-index: -1;
    }
    a {
      cursor: pointer;
      font-family: $header-font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0px;
      text-align: left;
    }

    nav {
      margin-left: auto;
      margin-right: 30px;
      position: relative;
      &::after {
        content: '';
        height: 30px;
        width: 1px;
        border-right: 1px solid rgba(229, 229, 229, 0.619);
        position: absolute;
        top: 3px;
        right: 0;
      }
      @media screen and (max-width: 1033px) {
        margin-left: 0px;
        margin-right: 0px;
      }
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
    .header-logo-block-wrapper {
      display: flex;
      align-items: center;
      margin-left: 20px;
      @media screen and (max-width: 1033px) {
        margin-left: 10px;
      }
      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
    .header-logo-wrapper {
      padding-right: 24px;
      border-right: 1px solid rgba(229, 229, 229, 0.619);
      @media screen and (max-width: 1020px) {
        display: none;
      }

      .header-logo {
        width: 186px;
        height: 30px;
      }
    }

    .mob-header-navbar {
      @media screen and (max-width: 1023px) {
        display: block;
        position: absolute;
        top: 45px;
        width: 100%;
        height: calc(100dvh - 45px);
        display: flex;
        flex-direction: column;
        background-color: rgb(255, 255, 255);
      }
    }

    .header__nav {
      display: flex;
      flex-wrap: wrap;
      &:last-child {
        margin-right: 15px;
        @media screen and (max-width: 1033px) {
          margin-right: 0;
        }
      }

      @media screen and (max-width: 1023px) {
        width: 100%;
        flex-direction: column;
        height: 100%;
        overflow-y: scroll;
        padding: 10px 0;
        box-sizing: border-box;
        flex-wrap: nowrap;
        & > li {
          padding: 0 20px;
        }
      }

      .nav__link-wrapper .link-icon-wrapper .nav__link {
        @media screen and (max-width: 1023px) {
          font-size: 16px;
          line-height: 22px;
          font-weight: 500;
        }
      }
    }

    #header__logo-name {
      text-decoration: none;
      padding: 13px 23px;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      font-family: $header-font-family;
      color: $header-name;
      font-size: 19px;
      font-weight: 500;
      line-height: 23px;

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
    .header__dropdown-list-wrapper {
      padding-top: 9px;
      position: absolute;
      left: 0;
      right: 0;

      @media screen and (max-width: 1023px) {
        position: static;
        padding-top: 0;
      }

      &--right {
        position: absolute;
        padding-left: 3px;
        right: auto;
        left: 100%;
        top: 0;

        @media screen and (max-width: 1023px) {
          position: static;
        }
      }
    }
    .header__dropdown-list {
      background-color: rgb(255, 255, 255);
      left: 0;
      right: 0;
      width: 100%;
      box-shadow: 0px 2px 24px 0px #70707040;
      border-radius: 6px;
      width: 140px;

      &.wide {
        width: 200px
      }

      @media screen and (max-width: 1023px) {
        background-color: rgb(255, 255, 255);
        position: static;
        left: auto;
        right: auto;
        box-shadow: none;
        width: auto;

        li {
          padding-left: 20px;
        }

        .header__dropdown-list li {
          padding-left: 20px;
        }
      }
      .nav__link {
        padding: 12px 15px;
      }
      .nav__link.active {
      }
      .nav__link-wrapper {
        border-radius: 0px;

        .nav__link {
          border-radius: 0px;
        }
        &:hover {
          border-radius: 0px;
        }
        &:first-child {
          border-radius: 6px 6px 0 0;
          .nav__link {
            border-radius: 6px 6px 0 0;
          }
        }
        &:last-child {
          border-radius: 0 0 6px 6px;
          .nav__link {
            border-radius: 0 0 6px 6px;
          }
        }
        &:first-child:last-child {
          border-radius: 6px;
          .nav__link {
            border-radius: 6px;
          }
        }
        .header__dropdown-list--right {
          .nav__link-wrapper {
            &:first-child {
              .nav__link.active {
                border-radius: 6px 6px 0 0;
              }
            }
            &:last-child {
              .nav__link {
                border-radius: 0 0 6px 6px;
              }
            }
          }
        }
      }
    }
    .link-icon-wrapper a {
      display: flex;
      align-items: center;
      .nav__link-wrapper:hover {
        //   color: red;
      }
    }

    .nav__link-wrapper {
      //   color: red;
      a {
        color: $header-link;
        @media screen and (max-width: 1023px) {
          color: black;
        }
      }
      position: relative;
      a:hover {
        color: $header-primary;
        @media (max-width: 1023px) {
          color: inherit;
        }
      }
      &:hover {
        background: $header-link-active;
        border-radius: 6px;
        & > a {
          color: $header-primary;
        }
        .link-icon {
          filter: none;
          @media (max-width: 1023px) {
            filter: grayscale(1);
          }
        }
        span {
          color: $header-primary;
          @media (max-width: 1023px) {
            color: inherit;
          }
        }
      }

      @media screen and (max-width: 1023px) {
        &:hover {
          background: rgb(255, 255, 255);
        }
      }
    }
    .nav-submenu__link-wrapper {
      position: relative;
      &:hover {
        border-radius: 6px;
      }

      @media screen and (max-width: 1023px) {
        &:hover {
          background: rgb(255, 255, 255);
        }
      }
    }
    .nav__link {
      line-height: 15px;
      display: block;
      text-decoration: none;
      padding: 10px 15px;
      border-radius: 6px;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: -moz-none;
      -o-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      &::selection {
        background: transparent;
      }
      &::-moz-selection {
        background: transparent;
      }
      &:focus-visible {
        background: transparent;
        border: none;
      }

      @media screen and (max-width: 1023px) {
        color: black;
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }
    }
    .nested-nav__link {
      &::after {
        content: '';
        display: inline-block;
        margin-left: 10px;
        margin-bottom: 1px;
        vertical-align: bottom;
        width: 10px;
        min-width: 10px;
        height: 10px;
        background-image: url('./../../assets/images/left-arrow.svg');
        filter: invert(58%) sepia(99%) saturate(41%) hue-rotate(213deg) brightness(75.7%)
          contrast(119%);
        transform: rotate(270deg);
        @media screen and (max-width: 1023px) {
          width: 17px;
          height: 17px;
        }
      }

      &.mobile-location::after {
        content: '';
        position: absolute;
        right: 41px;
        top: 17px;
        display: block;
        margin-left: 10px;
        margin-bottom: 1px;
        vertical-align: bottom;
        width: 10px;
        height: 10px;
        background-image: url('./../../assets/images/left-arrow.svg');
        filter: invert(58%) sepia(99%) saturate(41%) hue-rotate(213deg) brightness(75.7%)
          contrast(119%);
        transform: rotate(270deg);
        @media screen and (max-width: 1023px) {
          width: 10px;
          height: 10px;
        }
      }

      &--open {
        @media screen and (max-width: 1023px) {
          color: #6588e8;
          background: $header-link-active;
        }
        &::after {
          filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(208deg) brightness(98%)
            contrast(119%);
          margin-bottom: 1px;
          transform: rotate(90deg);
        }
      }
    }

    .nested-nav__location-icon-blue {
      fill: $header-primary;
      filter: invert(40%) sepia(72%) saturate(1866%) hue-rotate(205deg) brightness(101%) contrast(104%) !important;
    }

    .nav__link.active {
      background: $header-link-active;
      color: $header-primary;
      .link-icon {
        filter: none;
      }

      @media screen and (max-width: 1023px) {
        background-color: inherit;
      }
    }

    .burger-menu,
    .burger-menu-open {
      display: none;
      -webkit-touch-callout: none; /* prevent callout to copy image, etc when tap to hold */
      -webkit-text-size-adjust: none; /* prevent webkit from resizing text to fit */
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0); /* prevent tap highlight color / shadow */
      -webkit-user-select: none; /* prevent copy paste, to allow, change 'none' to 'text' */
      @media screen and (max-width: 1023px) {
        // flex-basis: 18%;
        width: 53px;
        display: block;
      }
    }
    .header-mobile-logo {
      display: none;
      @media screen and (max-width: 1023px) {
        font-family: 'Poppins';
        display: block;
        font-weight: 500;
        font-size: 16px;
        text-decoration: none;
        padding: 9px 5px;
        -webkit-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        margin: 0 auto;
      }
    }
    .aside-arr-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .more-arr-aside {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background-image: url('./../../assets/images/left-arrow.svg');
      filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(213deg) brightness(118%)
        contrast(119%);
      transform: rotate(180deg);
      @media (max-width: 1023px) {
        transform: rotate(270deg);
        width: 15px;
        height: 15px;
        &--open {
          transform: rotate(90deg);
        }
      }
    }
    .link-icon {
      width: 16px;
      height: 16px;
      margin-right: 5px;
      margin-bottom: 2px;
      filter: grayscale(1);
      &:hover {
        filter: none;
      }
    }
  }

  //right header profile section
  &__profile-block {
    position: relative;
    color: #fff;
    font-size: 0.8125rem;
    font-weight: normal;
    text-transform: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    line-height: 45px;
    @media screen and (max-width: 1023px) {
      display: none;
    }

    .me-block {
      display: flex;
      align-items: center;
    }
    .me-link {
      -webkit-tap-highlight-color: transparent;
      user-select: none;
      color: $header-name;
      font-family: $header-font-family;
      font-size: 14px;
      font-weight: 500;
      line-height: 21px;
    }
    .user-img {
      width: 30px;
      height: 30px;
      background-image: url('./../../assets/icons/user-photo-placeholder.svg');
      background-color: gray;
      border-radius: 50%;
      margin-left: 8px;
      margin-right: 8px;
    }

    .more-arr {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      background-image: url('./../../assets/images/left-arrow.svg');
      transform: rotate(270deg);

      &--open {
        transform: rotate(90deg);
      }
    }

    .user-submenu-wrapper {
      padding-top: 14px;
      position: absolute;
      top: 31px;
      right: -15px;
    }
    .user-submenu {
      display: flex;
      flex-direction: column;
      width: 138px;
      border-radius: 6px;
      overflow: hidden;
      background-color: rgb(255, 255, 255);
      box-shadow: 0px 2px 24px 0px #70707040;
      @media screen and (max-width: 1023px) {
        right: -18px;
      }
    }
    .user-submenu-link {
      color: $header-link;
      font-size: 13px;
      line-height: 45px;
      text-decoration: none;
      padding: 0 15px;
      user-select: none;
      display: block;
      &:hover {
        background: $header-link-active;
        color: $header-primary;
      }
    }
  }
}
.user-img-fallback {
  width: 36px;
  height: 36px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-left: 5px;
  background-color: $header-link-active;
  color: $header-primary;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mobile-profile-info {
  display: none;
  @media screen and (max-width: 1023px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 8px;
    background: rgba(249, 249, 249, 1);
    height: 50px;
  }
}
.mobile-me-block {
  display: flex;
  align-items: center;
  height: 50px;
}
.mobile-me-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 10px;
}
.mobile-burger-actions {
  display: flex;
  align-items: center;
  flex-basis: 75px;
  justify-content: space-between;
  height: 50px;
}
.mobile-none {
  @media screen and (max-width: 1033px) {
    display: none;
  }
}
.header__block .nav__link-wrapper:hover .mobile-cip-block span {
  color: black;
}
.header__block .header__dropdown-list .header__dropdown-list .mobile-cip-block li {
  padding-left: 0;
}
.header__block .header__dropdown-list .mobile-cip-block .nav__link {
  padding-left: 0;
}
.mobile-cip-block {
  display: none;
  @media screen and (max-width: 1033px) {
    display: block;
    list-style: disc;
    margin-top: 10px;
    span {
      padding-left: 35px;
      font-size: 16px;
    }
    .nav__link-wrapper {
      margin-left: 55px;
    }
  }
}
.header__nav-mobile {
  @media screen and (max-width: 1033px) {
    .nested-nav__link--open {
      color: $header-primary !important;
      .link-icon {
        filter: none !important;

        &.nested-nav__location-icon-blue {
          fill: $header-primary;
          filter: invert(40%) sepia(72%) saturate(1866%) hue-rotate(205deg) brightness(101%) contrast(104%) !important;
        }
      }
    }
  }
}
.burger-place {
  display: none;
}
.header-logo-wrapper-mobile {
  padding-right: 24px;
  border-right: 1px solid rgba(229, 229, 229, 0.619);
  display: none;
  img {
    width: 130px;
  }
  @media screen and (max-width: 1033px) {
    img {
      width: 180px;
    }
    border-right: none;
    padding-right: 0;
    display: block;
  }
}
.menu__btn {
  display: none;
}
.admin-panel-link {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(101, 136, 232, 1);
  margin-left: 5px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  svg {
    filter: grayscale(0.3);
    width: 18px;
    height: 18px;
    transform: rotate(270deg);
  }
}
@media screen and (max-width: 1023px) {
  .menu__btn {
    display: flex;
    align-items: center;
    position: absolute;
    width: 26px;
    cursor: pointer;
    z-index: 1;
    padding-top: 16px;
    padding-bottom: 36px;
    padding-right: 52px;
    padding-left: 20px;
    margin-top: -19px;
    -webkit-tap-highlight-color: transparent;
  }
  .menu__btn > span,
  .menu__btn > span::after {
    display: block;
    position: absolute;
    width: 26px;
    height: 2px;
    background-color: rgb(0, 0, 0);
  }
  .menu__btn > span::after {
    content: '';
    top: 8px;
  }

  .burger-menu-open .menu__btn > span {
    transform: rotate(45deg);
  }
  .burger-menu-open .menu__btn > span::before {
    top: 0;
    transform: rotate(0);
  }
  .burger-menu-open .menu__btn > span::after {
    top: 0;
    transform: rotate(90deg);
  }
}
